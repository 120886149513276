import Vue from 'vue'
import VueRouter from 'vue-router'

import Apostas from "../views/Apostas"
import HorseRacing from "../views/HorseRacing"

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'HorseRacing',
        component: HorseRacing,
        meta: {}
    },
    {
        path: '/about/',
        name: 'Apostas',
        component: Apostas,
        meta: {},
        children: [
            {
                path: 'apostas',
                name: 'Apostas',
                component: Apostas,
                meta: {}
            },
        ]
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router