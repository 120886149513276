<template>
  <div id="app">
    <div class="header">
      <div class="logo">fortuna<span class="lan">br</span><span class="ls2">.pro</span></div>
    </div>
    <router-view />
    <div class="footer">
      <div class="container">
        <div class="grid">
          <div class="txt">
            <span>JOGO RESPONSÁVEL | CONTEÚDO APENAS PARA MAIORES DE 18 ANOS</span>

            <p>Conteúdo apenas para maiores de 18 anos. Consulte nossa Política de Jogo Responsável. Esse site fornece
              informações sobre jogos de azar. Como tal, estamos empenhados em garantir que todos os visitantes do site
              apostem de forma responsável.</p>

            <p>O Melhores Apostas Online não tem nenhuma responsabilidade sobre os jogos que são oferecidos neste site.
              Todas as regras, condições e regulamentações destas práticas são exclusivamente da Caixa Econômica Federal.
              Nós apenas fazemos a divulgação das loterias online no Brasil.</p>

            <p>Oferecemos-lhe revisões honestas e detalhadas sobre os melhores sítios de apostas em corridas de cavalos.
              De
              vez em quando, podemos receber um pequeno estipêndio por mencionar estes operadores. Mas não deixamos que
              isso afecte as nossas críticas e opiniões.</p>

            <p>Mais informações sobre a regulação:</p>
            <img src="/govbr-logo-large.png">
          </div>
          <div class="img">
            <div><img src="/jogo-responsavel-selo-pyt6256wr6apgybmk873b6ntntbvil3ydqfmxwyhro.png"></div>
            <div><img src="/begambleaware-100px-pyt64xrmgiqqmtiydjx1p1oz37wjc7kk35fu8s3pj4.png"></div>
            <div><img src="/gamcare6-pyt665y2ctfmhbq73tf91v1bc78mnqfz94mm326jnm.png"></div>
          </div>
        </div>

      </div>
      <div class="bottom-line">
        <div class="container">
          <div class="logo">fortuna<span class="lan">br</span><span class="ls2">.pro</span></div>
          <div class="copy">
            © 2023 Fortunabr.live è una marca de propriedade da NOONIC SRL.
            Todos os direitos reservados.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: #EBA837;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

head,
body {
  background: #333;
  position: relative;
  overflow-x: hidden;
  font-family: Roboto;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;

  @media screen and (max-width: 576px) {
    overflow-y: auto;
  }
}

#app {
  .header {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    .logo {
      padding: 20px 0;
      font-size: 50px;
      text-transform: uppercase;

      @media screen and (max-width: 576px) {
        font-size: 40px;
      }

      .lan {
        background-image: linear-gradient(90deg, #FFD214 0%, #FF662A 100%);
        color: #000;
        padding: 0 7px;
        margin: 0 3px;
        border-radius: 3px;
      }

      .ls2 {
        text-transform: lowercase;
        font-weight: 100;
      }
    }
  }

  .container {
    position: relative;
    margin: auto;
    z-index: 3;
    max-width: 1180px;
  }

  .s1 {
    background-image: url('./assets/kempton.jpeg');
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom: 30px;
    padding-top: 10px;

    .container {
      text-align: center;

      h1 {
        font-size: 52px;
        font-weight: 700;
        line-height: 1.2em;

        @media screen and (max-width: 576px) {
          font-size: 40px;
        }
      }

      h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.2em;

        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 576px) {
          flex-direction: column;

        }

        img {
          width: 50px;
          margin: 0 5px;

          @media screen and (max-width: 576px) {
            margin: 5px 0;
          }
        }
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 200;

        @media screen and (max-width: 576px) {
          flex-direction: column;
        }

        svg {
          margin: 0 5px;
        }
      }

      span {
        font-weight: 100;
      }
    }

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #232323;
      opacity: 0.89;
    }
  }

  .table {
    padding: 30px 0;

    .thead {
      @media screen and (max-width: 576px) {
        display: none;
      }

      .tr {
        background: #fff;
        color: #000;
        border-radius: 20px 20px 0 0;
        border: 1px solid #333;
        // border: 1px solid #fff;
      }
    }

    .tbody {
      .tr {
        background: #000;
        border: 1px solid #333;
        align-items: center;
        border-radius: 0 0 20px 20px;
        margin-bottom: -15px;
        position: relative;

        &.z-ind-0 {
          z-index: 5;
        }

        &.z-ind-1 {
          z-index: 4;
        }

        &.z-ind-2 {
          z-index: 3;
        }

        &.z-ind-3 {
          z-index: 2;
        }

        &.z-ind-4 {
          z-index: 1;
        }

        &:not(:first-child) {
          padding-top: 30px;
        }

        .td {
          &.img {
            img {
              max-width: 100%;
            }

            @media screen and (max-width: 576px) {
              grid-column: 1;
              grid-row: 1/3;
            }
          }

          &.bonus {
            img {
              max-width: 100%;
            }

            @media screen and (max-width: 576px) {
              grid-column: 1/3;
              grid-row: 3;
            }
          }

          &.rate-number {
            text-align: center;
            font-size: 52px;
            font-weight: 500;
            line-height: 1.2em;
            color: #ffd214;

            @media screen and (max-width: 576px) {
              grid-column: 2;
              grid-row: 1;
            }
          }

          &.rate {
            font-weight: 100;

            @media screen and (max-width: 576px) {
              grid-column: 2;
              grid-row: 2;
            }
          }

          &.btn {
            @media screen and (max-width: 576px) {
              grid-column: 1/3;
              grid-row: 4;
            }

            .a-us-btn {
              font-size: 16px;
              font-weight: 600;
              text-transform: uppercase;
              color: #131313;
              background-color: transparent;
              background-image: linear-gradient(90deg, #FFD214 0%, #FF662A 100%);
              border-radius: 8px 8px 8px 8px;
              padding: 16px 32px 16px 32px;
              text-decoration: none;

              @media screen and (max-width: 576px) {
                display: block;
                width: 100%;
                text-align: center;
              }

            }
          }
        }
      }
    }

    .tr {
      display: grid;
      grid-template-columns: 178px 1fr 130px 100px auto;
      gap: 20px;
      padding: 10px;

      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr auto;
        gap: 10px;
      }
    }
  }
}

.footer {
  padding-top: 30px;


  .grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 50px;

    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
    }

    .img {
      display: grid;
      grid-template-columns: 100%;
      gap: 10px;
      align-items: center;

      @media screen and (max-width: 576px) {
        padding-bottom: 30px;
      }

      div {
        text-align: center;
      }
    }

    .txt {
      font-weight: normal;
      color: #AAAAAA;
      padding-bottom: 30px;

      @media screen and (max-width: 576px) {
        padding-bottom: 15px;
      }

      span {
        color: #AAAAAA;
        font-weight: bold;
      }
    }
  }

  .bottom-line {
    background: #000;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #494A4A;
    padding: 26px 0px 24px 0px;

    .logo {
      padding: 20px 0;
      font-size: 30px;
      text-transform: uppercase;

      @media screen and (max-width: 576px) {
        font-size: 20px;
      }

      .lan {
        background-image: linear-gradient(90deg, #FFD214 0%, #FF662A 100%);
        color: #000;
        padding: 0 7px;
        margin: 0 3px;
        border-radius: 3px;
      }

      .ls2 {
        text-transform: lowercase;
        font-weight: 100;
      }
    }

    .copy {
      color: #ffd214;
      font-weight: normal;
    }
  }
}</style>
